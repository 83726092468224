export default [
  {
    key: "increasement",
    sortable: true,
    sortField: "id",
    label: "#",
    thClass: "w-50px",
  },
  {
    key: "firstName",
    sortable: true,
    sortField: "firstName",
    label: "field.firstName",
    stickyColumn: true,
    variant: "light",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "lastName",
    sortable: true,
    sortField: "lastName",
    label: "field.lastName",
    stickyColumn: true,
    variant: "light",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "email",
    sortable: true,
    sortField: "email",
    label: "field.email",
    stickyColumn: true,
    variant: "light",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "phoneNumber",
    sortable: true,
    sortField: "phoneNumber",
    label: "field.phoneNumber",
    stickyColumn: true,
    variant: "light",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "createdAt",
    sortable: true,
    sortField: "id",
    label: "field.createdDate",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "createdBy",
    label: "field.createdBy",
    thClass: "text-left",
    tdClass: "text-left",
  },
  {
    key: "actions",
    label: "field.action",
  },
];
